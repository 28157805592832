<template>
  <div>
    <form autocomplete="off">
      <v-card class="mb-4" elevation="2">
        <v-card-title>Detalii companie</v-card-title>
        <v-card-text>
          <div>
            <v-text-field v-model="form.franchisee.name" label="Nume" />
            <error-messages :errors="errors['franchisee.name']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.domain" label="Shop Domain" />
            <error-messages :errors="errors['franchisee.domain']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.admin_domain" label="Admin Domain" />
            <error-messages :errors="errors['franchisee.admin_domain']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.phone" label="Nr. Telefon" />
            <error-messages :errors="errors['franchisee.phone']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.email" label="Email" />
            <error-messages :errors="errors['franchisee.email']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.logofile_url" type="url" label="Logo URL" />
            <error-messages :errors="errors['franchisee.logofile_url']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.fevicon_url" type="url" label="Favicon URL " />
            <error-messages :errors="errors['franchisee.fevicon_url']" />
          </div>
        </v-card-text>
      </v-card>
      <v-card elevation="2">
        <v-card-title>Administrator</v-card-title>
        <v-card-text>
          <div>
            <v-text-field v-model="form.franchisee.admin.first_name" label="Prenume" />
            <error-messages :errors="errors['client.first_name']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.admin.last_name" label="Nume" />
            <error-messages :errors="errors['client.last_name']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.admin.email" label="Email" />
            <error-messages :errors="errors['client.email']" />
          </div>

          <div>
            <v-text-field v-model="form.franchisee.admin.phone" label="Nr. de telefon" />
            <error-messages :errors="errors['client.phone']" />
          </div>

        </v-card-text>
      </v-card>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddFranchisee',
  components: {
    ErrorMessages
  },
  data() {
    return {
      form: {
        franchisee: {
          name: '',
          domain: '',
          admin_domain: '',
          email: '',
          phone: '',
          logofile_url: '',
          fevicon_url: '',
          admin: {
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
          }
        }
      },
      errors: {}
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('sidebar/startLoading')
      this.$store.dispatch('franchisees/storeItem', this.form)
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.$store.dispatch('sidebar/stopLoading')
        })
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>

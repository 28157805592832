<template>
  <vs-sidebar :value="isSidebarActive" class="general-sidebar" position-right>
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ sidebarTitle }}</h4>
      <feather-icon class="cursor-pointer" icon="XIcon" @click.stop="closeSidebar"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <div class="p-4 scroll-area">
      <component :is="activeSidebarComponent" ref="activeComp"></component>
    </div>
    <div slot="footer" class="flex flex-wrap items-center p-6">
      <v-btn color="success" class="primary-button" :disabled="isLoading" :loading="isLoading" @click="triggerSubmit">{{
        confirmationButton }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="closeSidebar">Anuleaza</v-btn>
    </div>
  </vs-sidebar>
</template>

<script>
import AddComponent from '@/views/ProductManagement/Components/components/AddComponent'
import AddNewClientType from '@/views/Settings/ClientTypeManagement/components/AddNewClientType'
import EditClientType from '@/views/Settings/ClientTypeManagement/components/EditClientType'
import AddNewRole from '@/views/Roles/components/AddNewRole'
import EditRole from '@/views/Roles/components/EditRole'
import AddBaseProductAttribute from '@/views/ProductManagement/Attributes/components/AddBaseProductAttribute'
import AddProductComponentAttribute from '@/views/ProductManagement/Attributes/components/AddProductComponentAttribute'
import EditBaseProductAttribute from '@/views/ProductManagement/Attributes/components/EditBaseProductAttribute'
import EditProductComponentAttribute
  from '@/views/ProductManagement/Attributes/components/EditProductComponentAttribute'
import AddReseller from '@/views/Clients/AddReseller'
import AddPrivateClient from '@/views/Clients/AddPrivateClient'
import AddCompanyClient from '@/views/Clients/AddCompanyClient'
import AddNewClientReward from '@/views/ClientRewards/AddNewClientReward'
import EditClientReward from '@/views/ClientRewards/EditClientReward'
import AddAttributeValue from '@/views/ProductManagement/Attributes/AttributeValues/components/AddAttributeValue'
import EditAttributeValue from '@/views/ProductManagement/Attributes/AttributeValues/components/EditAttributeValue'
import AddAttributeGroup from '@/views/ProductManagement/AttributeGroups/components/AddAttributeGroup'
import EditAttributeGroup from '@/views/ProductManagement/AttributeGroups/components/EditAttributeGroup'
import AddPortfolioCustomers from '@/views/Settings/PortfolioCustomers/components/AddPortfolioCustomers'
import EditPortfolioCustomers from '@/views/Settings/PortfolioCustomers/components/EditPortfolioCustomers'
import AddPublicClient from '../views/Clients/AddPublicClient'
import AddItClient from '../views/Clients/AddItClient'
import AddFranchisee from '@/views/Franchisees/AddFranchisee'

export default {
  name: 'GeneralSidebar',
  computed: {
    isSidebarActive() {
      return this.$store.getters['sidebar/isSidebarActive']
    },
    isLoading() {
      return this.$store.getters['sidebar/isLoading']
    },
    activeSidebarComponent() {
      return this.$store.getters['sidebar/currentActiveSidebarComponent']
    },
    sidebarTitle() {
      return this.$store.getters['sidebar/sidebarTitle']
    },
    confirmationButton() {
      return this.$store.getters['sidebar/confirmationButton']
    }
  },
  components: {
    AddItClient,
    AddPublicClient,
    EditPortfolioCustomers,
    AddPortfolioCustomers,
    EditAttributeGroup,
    AddAttributeGroup,
    EditClientReward,
    AddNewClientReward,
    AddCompanyClient,
    AddPrivateClient,
    AddReseller,
    EditBaseProductAttribute,
    EditProductComponentAttribute,
    AddBaseProductAttribute,
    AddProductComponentAttribute,
    EditRole,
    AddNewRole,
    EditClientType,
    AddNewClientType,
    AddComponent,
    AddAttributeValue,
    EditAttributeValue,
    AddFranchisee
  },
  mounted: {

  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('sidebar/closeSidebar')
    },
    triggerSubmit() {
      this.$refs.activeComp.submitForm()
    },
  },
}
</script>

<style lang="scss">
.general-sidebar {
  .vs-sidebar {
    width: 400px;
    max-width: 400px;
  }

  .vs-sidebar--background,
  .vs-sidebar.vs-sidebar-parent.vs-sidebar-position-right {
    z-index: 60000;
    position: fixed !important;
    height: 100vh;
  }

  .scroll-area {
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 85px);

    &:not(.ps) {
      overflow-y: auto;
    }
  }
}
</style>
